<template>
  <div class="EnterpriseRegisterList">
    <list exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          operateColumnWidth="120px"
          :headers="headers"
          ref="list">
      <template>
        <div class="top-title-data">
          <div class="top-title">
            <particles-view></particles-view>
            <span class="top-title-name">企业数量</span>
            <span class="top-title-value">{{ topInfo.enterpriseCount }}</span>
          </div>
          <div class="top-title">
            <particles-view></particles-view>
            <span class="top-subinfo">入驻率：{{ topInfo.enterpriseCount ? Math.floor(topInfo.inEnterpriseCount / topInfo.enterpriseCount * 100) : 0 }}%</span>
            <span class="top-title-name">入驻企业</span>
            <span class="top-title-value">{{ topInfo.inEnterpriseCount }}</span>
          </div>
          <div class="top-title">
            <particles-view></particles-view>
            <span class="top-subinfo">流失率：{{ topInfo.enterpriseCount ? Math.floor(topInfo.lossEnterpriseCount / topInfo.enterpriseCount * 100) : 0 }}%</span>
            <span class="top-title-name">流失企业</span>
            <span class="top-title-value">{{ topInfo.lossEnterpriseCount }}</span>
          </div>
          <div class="top-title">
            <particles-view></particles-view>
            <span class="top-title-name">今日信息更新</span>
            <span class="top-title-value">{{ topInfo.updateEnterpriseCount }}</span>
          </div>
        </div>

      </template>
      <template #headerSlot>
        <v-button text="新增"
                  permission="add"
                  @click="create" />
        <v-button text="设置匹配条件"
                  permission="setTarget"
                  @click="setTarget" />
      </template>
      <template #searchSlot>
        <v-select label="企业招商状态"
                  v-model="searchParams.status"
                  :options="statusOps" />
        <v-input label="公司名称"
                 v-model="searchParams.enterpriseName" />
        <v-input-number label="匹配程度: 大于等于"
                        :max="100"
                        :width="60"
                        placeholder=""
                        class="percent-input"
                        v-model="searchParams.matchNum" />
        <v-select label="行业类别"
                  v-model="searchParams.industryType"
                  :options="opsMap.ENTERPRISE_INDUSTRY_TYPE" />
        <v-select label="企业人数"
                  v-model="searchParams.enterpriseNum"
                  :options="opsMap.ENTERPRISE_NUM" />
        <v-input label="法人"
                 v-model="searchParams.legalPerson" />
        <v-input label="企业联系人"
                 placeholder="请输入联系人"
                 v-model="searchParams.enterpriseContact" />
        <v-input label="联系电话"
                 v-model="searchParams.enterpriseContactPhone" />
        <v-select2 label="登记项目"
                   v-bind="communityParams"
                   v-model="searchParams.communityId" />
        <v-input label="跟踪人员"
                 v-model="searchParams.trackUserName" />
        <v-input label="创建人"
                 v-model="searchParams.inuserName" />
        <v-datepicker label="创建时间"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.beginIntime"
                      :endTime.sync="searchParams.endIntime" />
        <v-input label="操作人"
                 v-model="searchParams.updateUserName" />
        <v-datepicker label="最近修改时间"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.beginUpdateTime"
                      :endTime.sync="searchParams.endUpdateTime" />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看"
                  type="text"
                  permission="search"
                  @click="view(scope.row)" />
        <el-dropdown trigger="click"
                     @command="moreHandle"
                     v-if="getMorePermission(scope.row)"
                     placement="bottom">
          <span class="el-dropdown-link">
            更多
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, type: 1 }"
                              v-if="permission.update">
              <span>编辑</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 2 }"
                              v-if="permission.distribution">
              <span>招商分配</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 3 }"
                              v-if="scope.row.status === 0 && permission.binding">
              <span>绑定合同</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 4 }"
                              v-if="scope.row.status !== 2 & permission.loss">
              <span>流失</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 5 }"
                              v-if="scope.row.status === 2 && permission.turntointention">
              <span>转为意向</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <el-dialog title="请分配跟踪该企业的招商人员"
                 :visible.sync="allotVisible"
                 width="450px"
                 style="text-align: left">
        <el-form ref="allotForm"
                 :model="allotParams"
                 label-width="100px">
          <el-form-item label="登记项目"
                        prop="communityId"
                        :rules="[{ required: true, message: '请选择登记项目', trigger: 'change' }]">
            <v-select2 v-model="allotParams.communityId"
                       v-bind="communityParams"
                       :width="width" />
          </el-form-item>
          <el-form-item label="跟踪人员"
                        prop="trackUserId"
                        :rules="[{ required: true, message: '请选择跟踪人员', trigger: 'change' }]">
            <v-select2 v-model="allotParams.trackUserId"
                       v-bind="userParams"
                       :subjoin="{communityId: allotParams.communityId}"
                       :width="width" />
          </el-form-item>
        </el-form>
        <template #footer>
          <v-button type="default"
                    style="margin-right: 10px"
                    @click="onAllotCancel">取消</v-button>
          <v-button @click="onFollowerConfirm">确定</v-button>
        </template>
      </el-dialog>
      <el-dialog title="请选择绑定合同"
                 :visible.sync="contractVisible"
                 width="400px"
                 style="text-align: left">
        <el-form ref="contractForm"
                 :model="contractForm"
                 label-width="100px">
          <el-form-item label="合同名称"
                        prop="contractId"
                        :rules="{ required: true, message: '请选择绑定合同', trigger: 'change'}">
            <v-select2 v-model="contractForm.contractId"
                       v-bind="contractParams"
                       ref="contractSelect"
                       :width="width" />
          </el-form-item>
        </el-form>
        <template #footer>
          <v-button type="default"
                    style="margin-right: 10px"
                    @click="contractVisible = false">取消</v-button>
          <v-button @click="onContractConfirm">确定</v-button>
        </template>
      </el-dialog>
      <el-dialog title="请录入流失企业信息"
                 :visible.sync="lostVisible"
                 width="400px"
                 style="text-align: left">
        <el-form ref="lostForm"
                 :model="lostForm"
                 label-width="100px">
          <el-form-item label="流失原因"
                        prop="enterpriseLossReason"
                        :rules="{ required: true, message: '请选择流失原因', trigger: 'change'}">
            <v-select v-model="lostForm.enterpriseLossReason"
                      :options="opsMap.ENTERPRISE_LOSS_REASON"
                      :width="width" />
          </el-form-item>
          <el-form-item label="备注">
            <v-textarea :width="width"
                        v-model="lostForm.remark"></v-textarea>
          </el-form-item>
        </el-form>
        <template #footer>
          <v-button type="default"
                    style="margin-right: 10px"
                    @click="lostVisible = false">取消</v-button>
          <v-button @click="onLostConfirm">确定</v-button>
        </template>
      </el-dialog>
    </list>
  </div>

</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL, exportListURL, lostURL, setContractURL, setTrackUserURL, queryCommunityUserURL, changeStateURL, queryContractListURL, queryDictURL, queryTopInfoURL } from './api'
import { setStatusOps } from './map'
import { ParticlesView } from 'components/bussiness'
export default {
  name: 'EnterpriseRegisterList',
  components: {
    ParticlesView
  },
  computed: {
    permission () {
      return this.$store.state.permission
    }

  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: setStatusOps(1),
      communityParams,
      searchParams: {
        userName: '',
        status: undefined,
        orgId: '',
        fromtDate: '',
        toDate: ''
      },
      headers: [
        {
          prop: 'statusStr',
          label: '企业招商状态'
        },
        {
          prop: 'enterpriseName',
          label: '公司名称'
        },
        {
          prop: 'matchNum',
          label: '匹配程度',
          formatter: row => row.setTarget ? `${row.matchNum}%` : '未设置'
        },
        {
          prop: 'industryTypeStr',
          label: '行业类别'
        },
        {
          prop: 'enterpriseNumStr',
          label: '企业人数'
        },
        {
          prop: 'legalPerson',
          label: '法人'
        },
        {
          prop: 'enterpriseContact',
          label: '企业联系人'
        },
        {
          prop: 'enterpriseContactDuty',
          label: '联系人职务'
        },
        {
          prop: 'enterpriseContactPhone',
          label: '联系电话'
        },
        {
          prop: 'communityName',
          label: '登记项目'
        },
        {
          prop: 'trackUserName',
          label: '跟踪人员'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateUserName',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '最近修改时间'
        }
      ],
      serviceCommunityNum: 12,
      allotVisible: false,
      contractVisible: false,
      lostVisible: false,
      follower: undefined,
      width: 250,
      allotParams: {
        communityId: undefined,
        trackUserId: undefined
      },
      contractForm: {
        contractId: undefined
      },
      lostForm: {
        enterpriseLossReason: undefined,
        remark: ''
      },
      reasonOps: [],
      curRow: undefined,
      userParams: {
        searchUrl: queryCommunityUserURL,
        request: {
          text: 'username',
          value: 'userId'
        },
        handleData: data => data.map(item => {
          let arr = []
          if (item.username) {
            arr.push(item.username)
          }
          if (item.mobileNum) {
            arr.push(item.mobileNum)
          }
          return {
            id: item.id,
            name: arr.join('-')
          }
        })
      },
      contractParams: {
        searchUrl: queryContractListURL,
        request: {
          text: 'contractName',
          value: 'id'
        },
        response: {
          text: 'contractName',
          value: 'id'
        }
      },
      opsMap: {
        ENTERPRISE_LOSS_REASON: [],
        ENTERPRISE_INDUSTRY_TYPE: [],
        ENTERPRISE_NUM: []
      },
      topInfo: {
        enterpriseCount: 0,
        inEnterpriseCount: 0,
        lossEnterpriseCount: 0,
        updateEnterpriseCount: 0
      }
    }
  },
  mounted () {
    this.fetchOptions()
    this.fetchTopInfo()
  },
  methods: {
    create () {
      this.$router.push({
        name: 'enterpriseRegisterForm'
      })
    },
    view (row) {
      this.$router.push({
        name: 'enterpriseRegisterForm',
        query: {
          id: row.id,
          model: 'view'
        }
      })
    },
    moreHandle ({ row, type }) {
      this.curRow = row
      if (type === 1) {
        this.$router.push({
          name: 'enterpriseRegisterForm',
          query: {
            id: row.id,
            model: 'edit'
          }
        })
      }
      if (type === 2) {
        this.allotVisible = true
        this.$nextTick(() => {
          this.$refs.allotForm.resetFields()
          this.allotParams.communityId = this.curRow.communityId
          this.allotParams.trackUserId = this.curRow.trackUserId
        })
      } else if (type === 3) {
        this.contractVisible = true
        this.$nextTick(() => {
          this.$refs.contractSelect.clearValue()
          this.contractForm.contractId = this.curRow.contractId || undefined
          this.$nextTick(() => {
            this.$refs.contractForm.resetFields()
          })
        })
      } else if (type === 4) {
        this.lostVisible = true
        this.$nextTick(() => {
          this.$refs.lostForm.resetFields()
          this.lostForm.enterpriseLossReason = undefined
          this.lostForm.remark = ''
        })
      } else if (type === 5) {
        this.turnToWanted()
      }
    },
    async onFollowerConfirm () {
      try {
        let data = await this.$refs.allotForm.validate()
        if (data) {
          let { status } = await this.$axios.get(setTrackUserURL, {
            params: {
              id: this.curRow.id,
              ...this.allotParams
            }
          })
          if (status === 100) {
            this.$refs.list.searchData()
            this.allotVisible = false
          }
        }
      } catch (error) {
        // 表单校验不通过做处理
      }
    },
    onAllotCancel () {
      this.allotVisible = false
    },
    async onContractConfirm () {
      try {
        let result = await this.$refs.contractForm.validate()
        if (result) {
          let { status } = await this.$axios.get(setContractURL, {
            params: {
              contractId: this.contractForm.contractId,
              id: this.curRow.id
            }
          })
          if (status === 100) {
            this.$refs.list.searchData()
            this.contractVisible = false
          }
        }
      } catch (error) {

      }
    },
    async onLostConfirm () {
      try {
        let result = await this.$refs.lostForm.validate()
        if (result) {
          let { status } = await this.$axios.post(lostURL, {
            id: this.curRow.id,
            ...this.lostForm
          })
          if (status === 100) {
            this.$refs.list.searchData()
            this.fetchTopInfo()
            this.lostVisible = false
          }
        }
      } catch (error) {

      }
    },
    async turnToWanted () {
      let isConfirm = await this.$confirm('是否转为意向企业')
      if (isConfirm) {
        let { status } = await this.$axios.get(changeStateURL, {
          params: {
            id: this.curRow.id
          }
        })
        if (status === 100) {
          this.$refs.list.searchData()
          this.fetchTopInfo()
        }
      }
    },
    setTarget () {
      this.$router.push({
        name: 'setTargetEnterprise'
      })
    },
    // 查询下拉选项
    async fetchOptions () {
      let { data } = await this.$axios.get(queryDictURL, {
        params: {
          typeValueList: [
            'ENTERPRISE_LOSS_REASON',
            'ENTERPRISE_INDUSTRY_TYPE',
            'ENTERPRISE_NUM'
          ].join(',')
        }
      })
      if (data) {
        Object.keys(data).forEach(key => {
          this.opsMap[key] = data[key].map(({ id, name }) => ({ text: name, value: id }))
          if (!['ENTERPRISE_LOSS_REASON'].includes(key)) {
            this.opsMap[key].unshift({ text: '全部', value: undefined })
          }
        })
      }
    },
    // 查询卡片信息
    async fetchTopInfo () {
      let { data } = await this.$axios.get(queryTopInfoURL)
      this.topInfo = data
    },
    getMorePermission (row) {
      return this.permission.update ||
        this.permission.distribution ||
        this.permission.binding ||
        (this.permission.loss && row.status !== 2) ||
        (this.permission.turntointention && row.status === 2)
    }
  }
}
</script>
<style lang="scss" scoped>
.top-title-data {
  width: 100%;
  display: flex;
  align-content: flex-start;
  margin-bottom: 15px;

  .top-title {
    width: 265px;
    height: 154px;
    background: linear-gradient(
      200deg,
      rgba(32, 217, 251, 1) 0%,
      rgba(27, 140, 242, 1) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 16px;
    border-radius: 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    &:nth-child(1) {
      background: linear-gradient(
        200deg,
        rgba(32, 217, 251, 1) 0%,
        rgba(27, 140, 242, 1) 100%
      );
    }
    &:nth-child(2) {
      background: linear-gradient(
        209deg,
        rgba(39, 217, 155, 1) 0%,
        rgba(10, 180, 227, 1) 100%
      );
    }
    &:nth-child(3) {
      background: linear-gradient(
        49deg,
        rgba(89, 103, 246, 1) 0%,
        rgba(104, 181, 255, 1) 100%
      );
    }
    &:nth-child(4) {
      background: linear-gradient(
        200deg,
        rgba(32, 217, 251, 1) 0%,
        rgba(27, 140, 242, 1) 100%
      );
    }

    canvas {
      position: absolute;
    }
    padding-left: 20px;
    span {
      color: white;
    }
    .top-subinfo {
      font-size: 14px;
    }

    .top-title-value {
      font-size: 40px;
      font-weight: 500;
    }

    .top-title-name {
      font-size: 22px;
    }
  }
}
.percent-input {
  &::after {
    content: "%";
  }
}
</style>
