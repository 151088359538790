var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "EnterpriseRegisterList" },
    [
      _c(
        "list",
        {
          ref: "list",
          attrs: {
            exportPermission: "export",
            searchUrl: _vm.searchUrl,
            exportUrl: _vm.exportUrl,
            searchParams: _vm.searchParams,
            operateColumnWidth: "120px",
            headers: _vm.headers,
          },
          on: {
            "update:searchParams": function ($event) {
              _vm.searchParams = $event
            },
            "update:search-params": function ($event) {
              _vm.searchParams = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "headerSlot",
              fn: function () {
                return [
                  _c("v-button", {
                    attrs: { text: "新增", permission: "add" },
                    on: { click: _vm.create },
                  }),
                  _c("v-button", {
                    attrs: { text: "设置匹配条件", permission: "setTarget" },
                    on: { click: _vm.setTarget },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "searchSlot",
              fn: function () {
                return [
                  _c("v-select", {
                    attrs: { label: "企业招商状态", options: _vm.statusOps },
                    model: {
                      value: _vm.searchParams.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "status", $$v)
                      },
                      expression: "searchParams.status",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "公司名称" },
                    model: {
                      value: _vm.searchParams.enterpriseName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "enterpriseName", $$v)
                      },
                      expression: "searchParams.enterpriseName",
                    },
                  }),
                  _c("v-input-number", {
                    staticClass: "percent-input",
                    attrs: {
                      label: "匹配程度: 大于等于",
                      max: 100,
                      width: 60,
                      placeholder: "",
                    },
                    model: {
                      value: _vm.searchParams.matchNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "matchNum", $$v)
                      },
                      expression: "searchParams.matchNum",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "行业类别",
                      options: _vm.opsMap.ENTERPRISE_INDUSTRY_TYPE,
                    },
                    model: {
                      value: _vm.searchParams.industryType,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "industryType", $$v)
                      },
                      expression: "searchParams.industryType",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "企业人数",
                      options: _vm.opsMap.ENTERPRISE_NUM,
                    },
                    model: {
                      value: _vm.searchParams.enterpriseNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "enterpriseNum", $$v)
                      },
                      expression: "searchParams.enterpriseNum",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "法人" },
                    model: {
                      value: _vm.searchParams.legalPerson,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "legalPerson", $$v)
                      },
                      expression: "searchParams.legalPerson",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "企业联系人", placeholder: "请输入联系人" },
                    model: {
                      value: _vm.searchParams.enterpriseContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "enterpriseContact", $$v)
                      },
                      expression: "searchParams.enterpriseContact",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "联系电话" },
                    model: {
                      value: _vm.searchParams.enterpriseContactPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchParams,
                          "enterpriseContactPhone",
                          $$v
                        )
                      },
                      expression: "searchParams.enterpriseContactPhone",
                    },
                  }),
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: { label: "登记项目" },
                        model: {
                          value: _vm.searchParams.communityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "communityId", $$v)
                          },
                          expression: "searchParams.communityId",
                        },
                      },
                      "v-select2",
                      _vm.communityParams,
                      false
                    )
                  ),
                  _c("v-input", {
                    attrs: { label: "跟踪人员" },
                    model: {
                      value: _vm.searchParams.trackUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "trackUserName", $$v)
                      },
                      expression: "searchParams.trackUserName",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "创建人" },
                    model: {
                      value: _vm.searchParams.inuserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "inuserName", $$v)
                      },
                      expression: "searchParams.inuserName",
                    },
                  }),
                  _c("v-datepicker", {
                    attrs: {
                      label: "创建时间",
                      type: "rangedatetimer",
                      startTime: _vm.searchParams.beginIntime,
                      endTime: _vm.searchParams.endIntime,
                    },
                    on: {
                      "update:startTime": function ($event) {
                        return _vm.$set(_vm.searchParams, "beginIntime", $event)
                      },
                      "update:start-time": function ($event) {
                        return _vm.$set(_vm.searchParams, "beginIntime", $event)
                      },
                      "update:endTime": function ($event) {
                        return _vm.$set(_vm.searchParams, "endIntime", $event)
                      },
                      "update:end-time": function ($event) {
                        return _vm.$set(_vm.searchParams, "endIntime", $event)
                      },
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "操作人" },
                    model: {
                      value: _vm.searchParams.updateUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "updateUserName", $$v)
                      },
                      expression: "searchParams.updateUserName",
                    },
                  }),
                  _c("v-datepicker", {
                    attrs: {
                      label: "最近修改时间",
                      type: "rangedatetimer",
                      startTime: _vm.searchParams.beginUpdateTime,
                      endTime: _vm.searchParams.endUpdateTime,
                    },
                    on: {
                      "update:startTime": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "beginUpdateTime",
                          $event
                        )
                      },
                      "update:start-time": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "beginUpdateTime",
                          $event
                        )
                      },
                      "update:endTime": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "endUpdateTime",
                          $event
                        )
                      },
                      "update:end-time": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "endUpdateTime",
                          $event
                        )
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "operateSlot",
              fn: function (scope) {
                return [
                  _c("v-button", {
                    attrs: { text: "查看", type: "text", permission: "search" },
                    on: {
                      click: function ($event) {
                        return _vm.view(scope.row)
                      },
                    },
                  }),
                  _vm.getMorePermission(scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click", placement: "bottom" },
                          on: { command: _vm.moreHandle },
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _vm._v("\n          更多\n          "),
                            _c("i", { staticClass: "el-icon-arrow-down" }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _vm.permission.update
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: { row: scope.row, type: 1 },
                                      },
                                    },
                                    [_c("span", [_vm._v("编辑")])]
                                  )
                                : _vm._e(),
                              _vm.permission.distribution
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: { row: scope.row, type: 2 },
                                      },
                                    },
                                    [_c("span", [_vm._v("招商分配")])]
                                  )
                                : _vm._e(),
                              scope.row.status === 0 && _vm.permission.binding
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: { row: scope.row, type: 3 },
                                      },
                                    },
                                    [_c("span", [_vm._v("绑定合同")])]
                                  )
                                : _vm._e(),
                              (scope.row.status !== 2) & _vm.permission.loss
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: { row: scope.row, type: 4 },
                                      },
                                    },
                                    [_c("span", [_vm._v("流失")])]
                                  )
                                : _vm._e(),
                              scope.row.status === 2 &&
                              _vm.permission.turntointention
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: { row: scope.row, type: 5 },
                                      },
                                    },
                                    [_c("span", [_vm._v("转为意向")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          [
            _c("div", { staticClass: "top-title-data" }, [
              _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("particles-view"),
                  _c("span", { staticClass: "top-title-name" }, [
                    _vm._v("企业数量"),
                  ]),
                  _c("span", { staticClass: "top-title-value" }, [
                    _vm._v(_vm._s(_vm.topInfo.enterpriseCount)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("particles-view"),
                  _c("span", { staticClass: "top-subinfo" }, [
                    _vm._v(
                      "入驻率：" +
                        _vm._s(
                          _vm.topInfo.enterpriseCount
                            ? Math.floor(
                                (_vm.topInfo.inEnterpriseCount /
                                  _vm.topInfo.enterpriseCount) *
                                  100
                              )
                            : 0
                        ) +
                        "%"
                    ),
                  ]),
                  _c("span", { staticClass: "top-title-name" }, [
                    _vm._v("入驻企业"),
                  ]),
                  _c("span", { staticClass: "top-title-value" }, [
                    _vm._v(_vm._s(_vm.topInfo.inEnterpriseCount)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("particles-view"),
                  _c("span", { staticClass: "top-subinfo" }, [
                    _vm._v(
                      "流失率：" +
                        _vm._s(
                          _vm.topInfo.enterpriseCount
                            ? Math.floor(
                                (_vm.topInfo.lossEnterpriseCount /
                                  _vm.topInfo.enterpriseCount) *
                                  100
                              )
                            : 0
                        ) +
                        "%"
                    ),
                  ]),
                  _c("span", { staticClass: "top-title-name" }, [
                    _vm._v("流失企业"),
                  ]),
                  _c("span", { staticClass: "top-title-value" }, [
                    _vm._v(_vm._s(_vm.topInfo.lossEnterpriseCount)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("particles-view"),
                  _c("span", { staticClass: "top-title-name" }, [
                    _vm._v("今日信息更新"),
                  ]),
                  _c("span", { staticClass: "top-title-value" }, [
                    _vm._v(_vm._s(_vm.topInfo.updateEnterpriseCount)),
                  ]),
                ],
                1
              ),
            ]),
          ],
          _c(
            "el-dialog",
            {
              staticStyle: { "text-align": "left" },
              attrs: {
                title: "请分配跟踪该企业的招商人员",
                visible: _vm.allotVisible,
                width: "450px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.allotVisible = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "v-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "default" },
                          on: { click: _vm.onAllotCancel },
                        },
                        [_vm._v("取消")]
                      ),
                      _c("v-button", { on: { click: _vm.onFollowerConfirm } }, [
                        _vm._v("确定"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "el-form",
                {
                  ref: "allotForm",
                  attrs: { model: _vm.allotParams, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "登记项目",
                        prop: "communityId",
                        rules: [
                          {
                            required: true,
                            message: "请选择登记项目",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { width: _vm.width },
                            model: {
                              value: _vm.allotParams.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.allotParams, "communityId", $$v)
                              },
                              expression: "allotParams.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "跟踪人员",
                        prop: "trackUserId",
                        rules: [
                          {
                            required: true,
                            message: "请选择跟踪人员",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              subjoin: {
                                communityId: _vm.allotParams.communityId,
                              },
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.allotParams.trackUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.allotParams, "trackUserId", $$v)
                              },
                              expression: "allotParams.trackUserId",
                            },
                          },
                          "v-select2",
                          _vm.userParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticStyle: { "text-align": "left" },
              attrs: {
                title: "请选择绑定合同",
                visible: _vm.contractVisible,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.contractVisible = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "v-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              _vm.contractVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c("v-button", { on: { click: _vm.onContractConfirm } }, [
                        _vm._v("确定"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "el-form",
                {
                  ref: "contractForm",
                  attrs: { model: _vm.contractForm, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "合同名称",
                        prop: "contractId",
                        rules: {
                          required: true,
                          message: "请选择绑定合同",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            ref: "contractSelect",
                            attrs: { width: _vm.width },
                            model: {
                              value: _vm.contractForm.contractId,
                              callback: function ($$v) {
                                _vm.$set(_vm.contractForm, "contractId", $$v)
                              },
                              expression: "contractForm.contractId",
                            },
                          },
                          "v-select2",
                          _vm.contractParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticStyle: { "text-align": "left" },
              attrs: {
                title: "请录入流失企业信息",
                visible: _vm.lostVisible,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.lostVisible = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "v-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              _vm.lostVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c("v-button", { on: { click: _vm.onLostConfirm } }, [
                        _vm._v("确定"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "el-form",
                {
                  ref: "lostForm",
                  attrs: { model: _vm.lostForm, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "流失原因",
                        prop: "enterpriseLossReason",
                        rules: {
                          required: true,
                          message: "请选择流失原因",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.opsMap.ENTERPRISE_LOSS_REASON,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.lostForm.enterpriseLossReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.lostForm, "enterpriseLossReason", $$v)
                          },
                          expression: "lostForm.enterpriseLossReason",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-textarea", {
                        attrs: { width: _vm.width },
                        model: {
                          value: _vm.lostForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.lostForm, "remark", $$v)
                          },
                          expression: "lostForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }